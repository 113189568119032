section.testimonials{
    height: auto;
}
section.testimonials .swiper-container{
    width: 55%;
}
section.testimonials div.main{
    transform: translateY(50px);
    opacity: 0;
    transition: all .3s;
}
section.testimonials div.main.active{
    transform: translateY(0px);
    opacity: 1;
}
section.testimonials .swiper-container .swiper-items{
    padding: 1rem;
    height: auto;
    margin: 0 auto;
    border-radius: 20px;
    background-color: var(--color-bg-variant);
    text-align: center;
    margin-bottom: 3rem;
}
section.testimonials .swiper-container .swiper-items .img-bg{
    padding: .5rem;
    width: 90px;
    height: 90px;
    background-color: var(--color-primary-variant);
    border-radius: 50%;
    margin: 0 auto;
}
section.testimonials .swiper-container .swiper-items .img-bg img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
section.testimonials .swiper-container .swiper-items p{
    width: 100%;
}
@media screen and (max-width: 1112px) {
    section.testimonials .swiper-container{
        width: 70%;
    }
}
@media screen and (max-width: 730px) {
    section.testimonials .swiper-container{
        width: 90%;
    }
}
@media screen and (max-width: 546px) {
    section.testimonials .swiper-container{
        width: 100%;
    }
}