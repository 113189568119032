section.services .grid-container {
    display: grid;
    grid-template-columns: repeat(3, 30%);
    grid-template-rows: 450px;
    justify-content: space-between;
}
section.services .grid-container article,
section.services .grid-container article .header{
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
}
section.services .grid-container article{
    width: 100%;
    height: 100%;
    background-color: var(--color-bg-variant);
    border: 2px solid var(--color-primary);
    transition: all .3s ease-in-out;
}
section.services .grid-container article.grid-items{
    transform: translateX(-30px);
    opacity: 0;
    transition: all .3s;
}
section.services .grid-container article.grid-items.two{
    transform: translateY(40px);
}
section.services .grid-container article.grid-items.active{
    transform: translateX(0px);
    opacity: 1;
}
section.services .grid-container article:hover{
    background-color: transparent;
}
section.services .grid-container article.extra{
    height: 500px;
}
section.services .grid-container article .header{
    height: 80px;
    background-color: var(--color-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: .5rem;
}
section.services .grid-container article .header h4{
    color: var(--color-bg-variant);
    font-weight: 600;
}
section.services .grid-container article ul{
    margin: 0 auto;
    height: calc(100% - 90px);
    width: 98%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
}
section.services .grid-container article ul li{
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}
section.services .grid-container article ul li div{
    width: 20%;
    height: 100%;
}
section.services .grid-container article ul li div.one{
    display: flex;
    justify-content: center;
    align-items: center;
}
section.services .grid-container article ul li div.one .bicheck{
    font-size: .9rem;
    color: var(--color-light);
}
section.services .grid-container article ul li div.two{
    width: 89%;
    color: var(--color-white);
}


@media screen and (max-width: 1387px) {
    section.services{
        height: auto;
    }
    section.services .grid-container {
        grid-template-columns: repeat(2, 30%);
        grid-template-rows: repeat(2, 450px);
        justify-content: center;
        grid-gap: 20px;
    }
    section.services .grid-container article{
        margin-bottom: 1rem;
    }
    section.services .grid-container article.extra{
        height: 100%;
    }
}
@media screen and (max-width: 1181px) {
    section.services .grid-container article ul li{
        justify-content: space-around;
    }
    section.services .grid-container article ul li div{
        width: 80%;
    }
    section.services .grid-container article ul li div.one{
        justify-content: flex-start;
        align-items: flex-start;
        width: 10%;
    }
}
@media screen and (max-width: 1150px) {
    section.services .grid-container {
        grid-template-columns: repeat(1, 70%);
        grid-template-rows: repeat(3, 450px);
        justify-content: center;
        grid-gap: 20px;
    }
    section.services .grid-container article ul{
        width: 80%;
    }
}
@media screen and (max-width: 780px) {
    section.services .grid-container {
        grid-template-columns: repeat(1, 80%);
    }
}
@media screen and (max-width: 481px) {
    section.services .grid-container {
        grid-template-columns: repeat(1, 90%);
    }
}
@media screen and (max-width: 369px) {
    section.services .grid-container {
        grid-template-columns: repeat(1, 100%);
    }
}