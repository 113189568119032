@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
*{
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}
:root{
    --color-bg: rgb(31, 31, 56);    
    --color-bg-variant: #2c2c6c;
    --color-primary: #4db5ff;    
    --color-primary-variant: rgba(77, 181, 255 ,0.4);
    --color-white: #fff; 
    --color-light: rgba(255, 255, 255, 0.6);   

    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 90%;
}
html{
    scroll-behavior: smooth;
}
html::-webkit-scrollbar {
    width: .5rem;
}
html::-webkit-scrollbar-track {
    background: var(--color-light);
}
html::-webkit-scrollbar-thumb {
    background: var(--color-bg);
}
body{
    font-family: 'Poppins', sans-serif;
    background-color: var(--color-bg);
    color: var(--color-white);
    line-height: 1.7;
    text-transform: capitalize;
    background-image: url(./assets/bg.jpg);
    overflow-x: hidden;
    position: relative;
}

/* GENERAL STYLES */
.container{
    width: var(--container-width-lg);
    height: 100Vh;
    margin: 0 auto;
}
h1,h2.h3,h4,h5{
    font-weight: 500;
}
h1{
    font-size: 2.5rem;
}
section{
    padding-top: 5rem;
}
section > h2,
section > h3{
    text-align: center;
    color: var(--color-light);
    font-size: 1rem;
}
section > h2{
    color: var(--color-primary);
    margin-bottom: 3rem;
    font-size: 2rem;
}
a{
    color: var(--color-primary);
    transition: all 400ms ease;
}
a:hover {
    color: var(--color-white);
}


@media screen and (max-width: 1024px) {
    .container{
        width: var(--container-width-md);
    }
}
@media screen and (max-width: 600px) {
    ::-webkit-scrollbar{
        display: none;
    } 
    .container{
        width: var(--container-width-sm);
    }
}