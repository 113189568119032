/* =========HEADER========== */
header{
    width: var(--container-width-lg);
    margin: 0 auto;
    height: auto;
    position: relative;
    padding-top: 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
}

.name {
    margin: 0 auto;
    text-align: center;
    margin-bottom: 2rem;
}
.name h1, .name h5{
    margin-bottom: .4rem;
}
.name h5{
    font-size: 1.1rem;
    font-size: 600;
}
.name h1{
    font-size: 2.3rem;
    font-weight: 800;
}

.cta{
    margin: 0 auto;
    text-align: center;
    margin-bottom: 2rem;
}
.dw--btn,.btn--primary{
    font-weight: 700;
    transition: all .4s ease-in-out;
}
.dw--btn{
    padding: 1.1rem 2rem;
    border: 2px solid var(--color-primary-variant);
    border-radius: 5px;
    margin-right: 1rem;
}
.dw--btn:hover,.btn--primary:hover{
    background-color: var(--color-white);
    color: var(--color-bg-variant);
    border-color: transparent;
}
.btn--primary {
    border: 3px solid var(--color-primary-variant);
    border-radius: 5px;
    padding: 1.1rem 1.5rem;
    background-color: var(--color-primary);
    color: var(--color-bg-variant);
}


.img{
    width: 350px;
    height: 440px;
    margin: 0 auto;
    background:  -webkit-linear-gradient(var(--color-primary), transparent);
    border-top-left-radius: 200px;
    border-top-right-radius: 200px;
    overflow: hidden;
}
.img img {
    width: 100%;
    height: 100%;
    transform: rotate(-2deg) translateY(65px) translateX(-10px);
    object-fit: contain;
}

.rl{
    position: absolute;
    bottom: 50px;
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.rl a{
    font-size: 1.4rem
}
.header--social{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.header--social:after{
    content: "";
    width: 2px;
    background-color: var(--color-primary);
    height: 2rem;
    z-index: 4;
}
.scrolldown a{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    transform: rotate(90deg);
    width: 140px;
    height: 140px;
}
.header--social a,.scrolldown a{
    font-size: 1.1rem;
}
@media screen and (max-width: 600px) {
    .rl{
        display: none;
    }
}
@media screen and (max-width: 500px){
    .img{
        width: 80%;
    }
}
@media screen and (max-width: 400px){
    .img{
        width: 85%;
        height: 300px;
    }
}
@media screen and (max-width: 350px) {
    .name h5{
        font-size: .9rem;
    }
    .name h1{
        font-size: 1.9rem;
    }
    .cta{
        
        width: 100%;
        height: 61px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .dw--btn,.btn--primary {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 61px;
        font-size: .9rem;
        padding: 0;
    }
    .dw--btn{
        width: 60%;
    }
    .btn--primary{
        width: 30%;
    }
    .img{
        width: 90%;
    }
}
@media screen and (max-width:300px){
    .img{
        width: 100%;
    }
}
@media screen and (max-width: 1024px) {
    header{
        width: var(--container-width-md);
    }
}
@media screen and (max-width: 600px) {
    header{
        width: var(--container-width-sm);
    }
}