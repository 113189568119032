nav{
    position: fixed;
    bottom: -60px;
    left: 50%;
    transform: translateX(-50%);
    padding: .7rem 1.7rem;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 40px;
    backdrop-filter: blur(5px);
    display: flex;
    gap: .8rem;
    z-index: 100;
    opacity: 0;
    animation: upward .8s linear 2s forwards;
}
@keyframes upward {
    100%{
        opacity: 1;
        bottom: 50px;
    }
}
nav a{
    background-color: transparent;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.1rem;
    color: var(--color-light);
}
nav a:hover {
    background-color: rgba(0, 0, 0, 0.3);
}
nav a:active{
    background-color: var(--color-bg);
}
nav a.active{
    background-color: var(--color-primary);
    color: var(--color-bg);
}

@media screen and (max-width: 396px) {
    nav{
        padding: .6rem 1.6rem;
    }
    nav a{
        width: 40px;
        height: 40px;
    }
}
@media screen and (max-width: 312px) {
    nav{
        padding: .4rem 1.4rem;
    }
    nav a{
        width: 30px;
        height: 30px;
    }
}